// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-beautyshare-tsx": () => import("./../../../src/pages/beautyshare.tsx" /* webpackChunkName: "component---src-pages-beautyshare-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-tsx": () => import("./../../../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-video-tsx": () => import("./../../../src/pages/video.tsx" /* webpackChunkName: "component---src-pages-video-tsx" */),
  "component---src-templates-single-post-tsx": () => import("./../../../src/templates/singlePost.tsx" /* webpackChunkName: "component---src-templates-single-post-tsx" */)
}

